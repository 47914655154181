import styled from "styled-components";

const ContactContainer = styled.div`
  background-color: ${props => props.theme.darkBlue}
  padding: ${props => props.theme.padding};
  text-align: center;
  h5 {
    color: white;
  }
  a:not(.button) {
    color: white;
    display: inline-block;
    margin: 1em 1em 0;
    text-decoration: none;
    transition: color 0.10s ease-in;
    &:hover {
      color: ${props => props.theme.lightBlue}
    }
    svg {
      font-size: 3.5em;
    }
  }
`;

export {ContactContainer}