import { useEffect, useState, createContext } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './global/Global.styles.tsx';
import './App.css';
import Header from './components/Header/Header.tsx';
import HeroSection from './components/HeroSection/HeroSection.tsx';
import Products from './components/Products/Products.tsx';
import About from './components/About/About.tsx';
import Locations from './components/Locations/Locations.tsx';
import Faqs from './components/Faqs/Faqs.tsx';
import Contact from './components/Contact/Contact.tsx';
import Footer from './components/Footer/Footer.tsx';
import Modal from './components/Modal/Modal.tsx';
import MobileMenu from './components/Header/MobileMenu.tsx';
import CallToAction from './components/CallToAction/CallToAction.tsx';

const theme = {
  width: 'clamp(328px, 95vw, 1320px)',
  padding: '5em 1em',
  darkBlue: 'rgb(44, 62, 80);',
  lightBlue: 'rgb(91, 156, 166)',
  // lightBlue: 'rgb(26, 188, 156)',
  darkerBlue: 'rgb(26, 37, 47)',
};

const navItems = {
  about: {
    display: 'About Us',
    uri: '#about',
  },
  products: {
    display: 'Products',
    uri: '#products',
  },
  locations: {
    display: 'Retail Locations',
    uri: '#locations',
  },
  faqs: {
    display: 'FAQs',
    uri: '#faqs',
  },
  order: {
    display: 'Order Online',
    uri: 'https://fermfoundations.square.site/',
    target: '_blank',
  },
};

let modalContextObj = {}; // gets automatically populated in a useEffect whenever products changes
let modalDefaultsObj = {}; // gets automatically populated in a useEffect whenever products changes
let productsObj = {};

export const ModalContext = createContext(modalContextObj);
export const ProductContext = createContext(productsObj);

export const modalDefaults = modalDefaultsObj;

function App() {
  const [products, setProducts] = useState({});

  // const productCodeList = Object.entries(products).map(([key, value]) => {
  //   return value.productCode;
  // });

  // console.log(productCodeList);

  useEffect(() => {
    // fetch products list
    fetch(process.env.PUBLIC_URL + '/datas/products.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => setProducts(data))
      .catch((error) =>
        console.error(
          'There has been a problem with your fetch operation:',
          error
        )
      );
  }, []);

  const [activeModal, setActiveModal] = useState(modalDefaultsObj);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    // create array of product codes
    // productsObj = products;
    // console.log(products);

    // create object to hold product data
    modalContextObj = Object.fromEntries(
      Object.keys(products).map((key) => [key, ''])
    );
    // create object to hold modal defaults
    modalDefaultsObj = Object.fromEntries(
      Object.keys(products).map((key) => [key, false])
    );

    // display modal
    Object.keys(products).map((p) => {
      if (activeModal[p]) {
        setIsModalOpen(true);
        return null;
      } else {
        setIsModalOpen(false);
        return null;
      }
    });
  }, [activeModal, products]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle isModalOpen={isModalOpen} />
        <Header navItems={navItems} setIsMobileMenuOpen={setIsMobileMenuOpen} />
        <HeroSection />
        <CallToAction title="Now Available at Mom's Organic Market!" buttonText="See Locations" buttonLink="#locations" buttonTarget="_self" />
        <About />
        <CallToAction />
        <ModalContext.Provider
          value={{
            setActiveModal: setActiveModal,
          }}
        >
          <ProductContext.Provider
            value={products}
          >
            <Products />
          </ProductContext.Provider>
        </ModalContext.Provider>
        <CallToAction />
        <Locations />
        <CallToAction />
        <Faqs />
        <Contact />
        <Footer />
        <ModalContext.Provider
          value={{
            setActiveModal: setActiveModal,
          }}
        >
          {Object.entries(products).map(([key, value]) => {
            if (activeModal[value.productCode]) {
              return (
                <Modal
                  name={value.name}
                  image={value.image}
                  description={value.description}
                />
              );
            } else {
              return '';
            }
          })}
        </ModalContext.Provider>
        {isMobileMenuOpen ? (
          <MobileMenu
            navItems={navItems}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        ) : (
          ''
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
