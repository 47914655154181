import styled from "styled-components";

const LocationsContainer = styled.div`
  > div {
    margin: 0 auto;
    max-width: 100%;
    padding: ${props => props.theme.padding};
    width: ${props => props.theme.width};
  }
  h3 {
    color: ${props => props.theme.darkBlue}
    + p {
      text-align: center;
      margin-bottom: 1em;
    }
  }
`;
const LocationsLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap:wrap;

  .icon-container {
    margin: 0 1.5em 1em;
    text-align: center;
    > h6 {
      margin-top:10px;
      text-align: center;
    }
  }

  @media all and (max-width: 992px) {
    .icon-container {
      flex-basis: calc(50% - 3em);
    }
  }

  div[class$='icon'] {
    align-items: center;
    border-radius: 100%;
    color:white;
    display: inline-flex;
    height:3.5em;
    justify-content: center;
    margin: 1em 0.25em 0;
    width:3.5em;
    > i {
      font-style: normal;
      font-weight: bold;
    }
  }

  .mk-icon {
    background-color: crimson;
    > i::after {
      content: 'MK';
    }
  }

  .bck-icon {
    background-color: gold;
    color: black !important;
    > i::after {
      content: 'BCK';
    }
  }

  .hbck-icon {
    background-color: darkorange;
    > i::after {
      content: 'HBCK';
    }
  }

  .tkr-icon {
    background-color: lightpink;
    color: black !important;
    > i::after {
      content: 'TKR';
    }
  }

  .sauce-icon {
    background-color: darkred;
    > i::after {
      content: 'HOT';
    }
  }
`;

const LocationsList = styled(LocationsLegend)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;

  @media all and (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  > h4, > h5 {
    grid-column: 1 / -1;
    text-transform: uppercase;
    text-align: left;
  }

  > h5{
    border-bottom: solid 1px black;
    padding-bottom: 10px;
  }

  > h4{
    text-align: center;
    font-size: 2.25em;
    text-decoration: underline;
  }
  p {
    line-height: 0.5em;
  }
`;

export {LocationsContainer, LocationsLegend, LocationsList}