import React from 'react';
// import { FaqsContainer } from './Faqs.styles.tsx';

// type Props = {}

function FaqsItem(faq) {
  return (
    <div>
      <h5>
        {faq.faq.question}
      </h5>
      <p>
        {faq.faq.answer}
      </p>
    </div> 
  );
}

export default FaqsItem;
