import React from 'react';
import { FooterContainer } from './Footer.styles.tsx';

// type Props = {}

function Footer() {
  return (
    <FooterContainer>
      Copyright &copy; {new Date().getFullYear()} Ferm Foundations LLC.
    </FooterContainer>
  );
}

export default Footer;
