import React, { useEffect, useState } from 'react';
import { LocationsContainer, LocationsLegend, LocationsList } from './Locations.styles.tsx';

// interface Locations {
//   state: string,
//   street: string,
//   city: string,
//   zip: number,
// }

// type Props = {}


function About() {

  const [locations, setLocations] = useState({});

  useEffect(() => {
    // fetch locations list
    fetch(process.env.PUBLIC_URL + '/datas/locations.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setLocations(data))
      .catch(error => console.error('There has been a problem with your fetch operation:', error));
  }, []);

  return (
    <LocationsContainer>
      <div id='locations'>
        <h3>Retail Locations</h3>
        <p>We are regularly expanding to more and more locations. Currently, you can find our product(s) at the following locations:</p>
        <LocationsLegend>
          {
            // products.map()
          }
          <div className="icon-container">
            <div className="mk-icon" title="Manchester Kimchi"><i></i></div>
            <h6>Manchester Kimchi</h6>
          </div>
          <div className="icon-container">
            <div className="bck-icon" title="Baltimore Curry Kimchi"><i></i></div>
            <h6>Baltimore Curry Kimchi</h6>
          </div>
          <div className="icon-container">
            <div className="hbck-icon" title="Habanero Baltimore Curry Kimchi"><i></i></div>
            <h6>Habanero Baltimore Curry Kimchi</h6>
          </div>
          <div className="icon-container">
            <div className="tkr-icon" title="Manchester Kimchi"><i></i></div>
            <h6>Tasty Kraut</h6>
          </div>
          <div className="icon-container">
            <div className="sauce-icon" title="Manchester Kimchi"><i></i></div>
            <h6>Chile Sauce</h6>
          </div>
        </LocationsLegend>
        <LocationsList>
          {
            Object.entries(locations).map(([state, counties]) => (
              <>
                <h4 key={state}>{state}</h4>
                {Object.entries(counties).map(([county, stores]) => (
                  <>
                    <h5 key={county}>{county}</h5>
                    {stores.map((store, index) => (
                      <div key={index}>
                        <h6>{store.name}</h6>
                        <p>{store.street}</p>
                        <p>{`${store.city}, ${store.state} ${store.zip}`}</p>
                        {
                          store.products.includes('MK') && (<div className="mk-icon" title="Manchester Kimchi"><i></i></div>)
                        }
                        {
                          store.products.includes('BCK') && (<div className="bck-icon" title="Baltimore Curry Kimchi"><i></i></div>)
                        }
                        {
                          store.products.includes('HBCK') && (<div className="hbck-icon" title="Habenero Baltimore Curry Kimchi"><i></i></div>)
                        }
                        {
                          store.products.includes('TKR') && (<div className="tkr-icon" title="Tasty Kraut"><i></i></div>)
                        }
                        {
                          store.products.includes('SAUCE') && (<div className="sauce-icon" title="Hot Sauce"><i></i></div>)
                        }
                        <p>{store.notes}</p>
                      </div>
                    ))}
                  </>
                ))}
              </>
            ))
          }
        </LocationsList> 
      </div>
    </LocationsContainer>
  );
}

export default About;
