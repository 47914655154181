import React from 'react';
import { MobileMenuContainer } from './Navigation.styles.tsx';


// type Props = {}


function MobileMenu({navItems, setIsMobileMenuOpen}) {

  const handleClose = () => {
    setIsMobileMenuOpen(false);
  }

  return (
    <>
      <MobileMenuContainer>
        <div onClick={handleClose}>X</div>
        <ul>
          {
            Object.keys(navItems).map((key) => (
              <li key={key}>
                <a target={navItems[key].target ? navItems[key].target : '_self'} href={navItems[key].uri} onClick={handleClose}>
                  {navItems[key].display}
                </a>
              </li>
            ))
          }
        </ul>
      </MobileMenuContainer>
      <section onClick={handleClose}></section>
    </>
  )
}

export default MobileMenu