import React, { useEffect, useState } from 'react'
import { NavList, HamburgerMenu } from './Navigation.styles.tsx';
import useWindowDimensions from '../../hooks/Hooks.tsx';

// type Props = {}

const getNavMenu = (navItems, setIsMobileMenuOpen, deviceWidth) => {
  if (deviceWidth > 1024) {
    return (
      <ul>
        {
          Object.keys(navItems).map((key) => (
            <li key={key}>
              <a target={navItems[key].target ? navItems[key].target : '_self'} href={navItems[key].uri}>
                {navItems[key].display}
              </a>
            </li>
          ))
        }
      </ul>
    );
  } else {
    return (
      <HamburgerMenu onClick={() => handleHamburgerClick(setIsMobileMenuOpen)}>
        <div></div>
      </HamburgerMenu>
    )
  }
}

const handleHamburgerClick = (setIsMobileMenuOpen) => {
  setIsMobileMenuOpen(true);
}

function Navigation({navItems, setIsMobileMenuOpen}) {

const { width } = useWindowDimensions();

  return (
    <NavList>
      {getNavMenu(navItems, setIsMobileMenuOpen, width)}
    </NavList>
  )
}

export default Navigation