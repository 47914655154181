import styled from "styled-components";

const AboutContainer = styled.div`
  background-color: ${props => props.theme.darkBlue};
  color : white;
  padding: ${props => props.theme.padding};
  > div {
    max-width: 100%;
    margin: 0 auto;
    width: ${props => props.theme.width};
  }
  p {
    line-height: 1.5em;
    margin-bottom: 1em;
  }
  a {
    color: yellow;
  }
`;

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media all and (max-width: 709px) {
    display: block;
    img {
      display: none;
    }
  }
  h5 {
    text-align: left;
  }
  > div {
   img {
    border: solid 1px black;
    box-shadow: 1em 1em 1em rgba(0,0,0,0.25);
   }
    
    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 2;
      @media all and (max-width: 709px) {
        grid-area: 1 / 1 / 2 / 5;
      }
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 5;
      @media all and (max-width: 709px) {
        grid-area: 2 / 1 / 3 / 5;
      }
    }
    &:nth-child(3) {
      grid-area: 2 / 2 / 3 / 5;
      @media all and (max-width: 709px) {
        grid-area: 3 / 1 / 4 / 5;
      }
    }
    &:nth-child(4) {
      grid-area: 3 / 1 / 4 / 4;
      @media all and (max-width: 709px) {
        grid-area: 4 / 1 / 5 / 5;
      }
    }
    &:nth-child(5) {
      grid-area: 4 / 1 / 5 / 4;
      @media all and (max-width: 709px) {
        grid-area: 5 / 1 / 6 / 5;
      }
    }
    &:nth-child(6) {
      grid-area: 3 / 4 / 5 / 5;
      @media all and (max-width: 709px) {
        grid-area: 6 / 1 / 7 / 5;
      }
    }
  }
`


export {AboutContainer, AboutGrid}