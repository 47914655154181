import styled from "styled-components";

const ItemContainer = styled.li`
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1em;
  header {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    > h4 {
      margin: 1em 0 0.25em;
      padding: 0 0.25em;
    }
  }
  > div {
    cursor: pointer;
    position: relative;
    margin-bottom: 2em;
    &::before, &::after {
      content: '';
      border-radius: 0.5em;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.10s ease-in;
    }
    &:hover {
      &::before {
        background-color: rgb(26, 188, 156, 0.75);

        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
      }
      &::after {
        content: '+';
        color: white;
        font-weight: 400;
        font-size: 10em;
        left: calc(100% / 2 - 0.25em);
        opacity: 1;
        position: absolute;
        top: calc(100% / 2 - 0.6em);
      }
    }
  }
  img {
    border: solid 1px ${props => props.theme.darkBlue};
    border-radius: 0.5em;
    box-shadow: 1em 1em 1em rgba(0,0,0,0.25);
    display: block;
    width: 100%;
  }
`;


export {ItemContainer}