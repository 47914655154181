// globalStyles.js
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle<{isModalOpen: boolean}>`
  * {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    box-sizing: border-box;
    font-size: 16px;
  }
  body {
    overflow: ${(props) => props.isModalOpen ? 'hidden' : 'initial'};
  }
  // body {
  //   position: ${(props) => props.isModalOpen ? 'fixed' : 'initial'};
  //   overflow-y: ${(props) => props.isModalOpen ? 'scroll' : 'auto'};
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  // }
  div[id] {
    scroll-margin-top: 70px;
  }
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
  h1 {
    font-size: clamp(2em, 4vw, 4em);
    text-transform: uppercase;
  }
  h2 {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: clamp(1em, 1.5vw, 1.5em);
    font-weight: 400;
  }
  h3 {
    border-bottom: solid 3px;
    font-size: clamp(2em, 3.25vw, 3em);
    margin: 0 0 1em 0;
    padding-bottom: 0.25em;
    text-transform: uppercase;
  }
  h4 {
    font-size: 1.75em;
  }
  h5 {
    font-size: 1.5em;
    text-transform: uppercase;
  }
  h6 {
    text-align: left;
  }
  p {
    font-size: clamp(0.85em, 1.5vw, 1.25em);
    margin: 1em 0;
  }
  p > a {
    margin: 0 0.3em;
  }
  .button {
    background-color: ${props => props.theme.lightBlue};
    border-radius: 10px;
    border: none;
    color: white;
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    margin: 1em 0 0;
    padding: 0.75em 1em;
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.darkBlue};
    }
  }
`;

export default GlobalStyle;