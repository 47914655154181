import React, { useEffect, useState } from 'react'
import Navigation from './Navigation.tsx';
import { TopBar, Logo } from './Header.styles.tsx';

// type Props = {}\

function Header({navItems, setIsMobileMenuOpen}) {
  const [header, setHeader] = useState("header")

  const listenScrollEvent = () => {
    if (window.scrollY === 0) {
      return setHeader('')
    } else if (window.scrollY > 50) {
      return setHeader('header-scroll')
    } 
  }
  
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <TopBar className={header}>
      <div>
        <a href="/">
          <img alt='logo' src={require('../../assets/logo/FFLogo2024-white.png')} />
          {/* <Logo className="logo">FERM FOUNDATIONS</Logo> */}
        </a>
        <Navigation navItems={navItems} setIsMobileMenuOpen={setIsMobileMenuOpen} />
      </div>
    </TopBar>
  )
}

export default Header