import React from 'react';
import { CallToActionContainer } from './CallToAction.styles.tsx';

// type Props = {}

function CallToAction({title = "ORDER ONLINE NOW", buttonText="Order Online", buttonLink="https://fermfoundations.square.site/", buttonTarget="_blank"} ) {
  return (
    <CallToActionContainer>
      <div id='CallToAction'>
        <h4>{title}</h4>
        <a className='button' target={buttonTarget} href={buttonLink} rel="noreferrer">{buttonText}</a>
      </div>
    </CallToActionContainer>
  );
}

export default CallToAction;
