import React, { useContext } from 'react'
import { ModalContainer } from './Modal.styles.tsx';
import { ModalContext, modalDefaults } from '../../App.js';

// type Props = {}
const handleClose = (modalContext) => {
  const {setActiveModal} = modalContext;
  setActiveModal(modalDefaults);
}

function Modal({name, description, image}) {
  const modalContext = useContext(ModalContext);
  return (
    <ModalContainer onClick={() => handleClose(modalContext)}>
      <div onClick={(e) => e.stopPropagation()}>
        <div onClick={() => handleClose(modalContext)}>X</div>
        <h3>{name}</h3>
        <img alt='product' src={require('../../assets/products/' + image)} />
        <p>{description}</p>
      </div>
    </ModalContainer>
  )
}

export default Modal