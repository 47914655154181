import styled from "styled-components";

const ProductsContainer = styled.div`
  color: ${props => props.theme.darkBlue};
  padding: ${props => props.theme.padding};
  > div {
    max-width: 100%;
    margin: 0 auto;
    width:  ${props => props.theme.width};

    ul {
      display: flex;
      list-style-type: none;
      text-align: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      > li {
        flex-basis: calc(25% - 2em);
        @media all and (max-width: 1036px) {
          flex-basis: calc(50% - 2em);
          margin-bottom: 2em;
        }
      }
    }
  }
  .slick-slide img {
    margin: 0 auto;
    max-width: 100%;
  }
`;


export {ProductsContainer}