import styled from "styled-components";

// need to fix modal close button positioning
// need to finish fixing modal responsiveness

const ModalContainer = styled.div`
background-color: rgba(0,0,0,0.75);
position: fixed;
width: 100%;
height: 100vh;
top: 0;
left: 0;
z-index: 3;
> div {
  background-color: white;
  border: solid 1px grey;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0.8);
  left: calc(50% - (${props => props.theme.width} / 2));
  height: 95vh;
  padding: clamp(1vw, 5vw, 3em) clamp(1vw, 5vw, 10em);
  position: fixed;
  overflow: auto;
  text-align: center;
  top: calc(50% - (95vh / 2));
  width: ${props => props.theme.width};
  > div { // close button
    color: ${props => props.theme.darkBlue};
    cursor: pointer;
    position: absolute; 
    right: clamp(0.25em, 3vw, 1em);
    top: 0.5em;
    font-weight: 700;
    font-size: 2em;
    transition: color 0.10s ease-in;
    &:hover {
      color: ${props => props.theme.lightBlue};
    }
  }
  h3 {
    margin-bottom: 0.25em;
  }
  img {
    width: clamp(20vw, 50vw, 375px);
    max-width: 100%;
  }
  p {
    margin-top: 1em;
    text-align: left;
    font-size: 1.15em;
    white-space: pre-line;
  }
}
`;

export {ModalContainer}