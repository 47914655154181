import styled from "styled-components";

const FaqsContainer = styled.div`
  background-color: ${props => props.theme.lightBlue};
  color: white;
  padding: ${props => props.theme.padding};
  > div {
    margin: 0 auto;
    max-width:100%;
    width: ${props => props.theme.width};
    }
`;


export {FaqsContainer}