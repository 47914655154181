import React from 'react'
import { HeroContainer } from './HeroSection.styles.tsx';

type Props = {}

function HeroSection() {
  return (
    <HeroContainer>
      {/* change h1 to a keyword -- change h2 to categories on pages (section headers) -- change h3 sub sections */}
      <h1>
        American-Fusion<br />Kimchi and Hot Sauce
      </h1>
    </HeroContainer>
  )
}

export default HeroSection