import styled from "styled-components";

const TopBar = styled.header`
  align-items: center;
  background-color: ${props => props.theme.darkBlue};
  display: flex;
  justify-content: center;
  height: 100px;
  padding: 0 1em;
  position: sticky;
  transition: height 0.10s ease-in;
  top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: solid 1px black;

   &.header-scroll {
    height: 50px;
    img {
      width: 8em;
    }
   }
  
  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: ${props => props.theme.width};
    @media all and (max-width: 1370px) {
      width: 100%;
    }
  }
  a {
    text-decoration: none;
  }
  img {
    width: 15em;
  }
`;

const Logo = styled.div`
color: white;
  font-size: clamp(1em, 5vw, 1.75em);
  font-weight: 700;
`;

export {TopBar, Logo}