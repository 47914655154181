import styled from "styled-components";

const CallToActionContainer = styled.div`
  background-color: firebrick;
  border-top: solid 10px white;
  border-bottom: solid 10px white;
  color : white;
  padding: 40px;
  text-transform: uppercase;
  > div {
    max-width: 100%;
    margin: 0 auto;
    text-align:center;
    width: ${props => props.theme.width};
  }
  a {
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
  }
`;

export {CallToActionContainer}