import React, { useContext } from 'react';
import { ItemContainer } from './ProductItem.styles.tsx';
import { ModalContext } from '../../../App.js';

// type Props = {}

function Products({productCode, productName, handleClick}) {
  const modalContext = useContext(ModalContext);
  return (
    <ItemContainer>
      <header>
        <h4>
          {productName(productCode)}
        </h4>
      </header>
      <div onClick={() => handleClick(productCode, modalContext)}>
        <img alt='product' src={require('../../../assets/products/' + productCode + '2024.jpg')} />
      </div>
    </ItemContainer>
  );
}

export default Products;
