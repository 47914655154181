import styled from "styled-components";

const NavList = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    list-style-type: none;

    li {
      padding: 0.5em;
      transition: background-color 0.1s ease-in;
      &:not(:first-child) {
        margin-left: 2em;
        @media all and (max-width: 1370px) {
          margin-left: 2.35vw;
        }
      }
      &:last-child {
        background-color: white;
        border-radius: 10px;
        background-color: white;
        &:hover {
          background-color: ${props => props.theme.lightBlue};
          a {
            color: white;
          }
        }
        a {
          color:  ${props => props.theme.darkBlue};
        }
      }
      &:hover {
        a {
          color: ${props => props.theme.lightBlue};
        }
      }

      a {
        background: none;
        border:none;
        color: white;
        cursor: pointer;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        transition: color 0.1s ease-in;
      }
    }
  }
`;

const HamburgerMenu = styled.div`
cursor: pointer;
height: 22px;
> div {
  height: 4px;
  position: relative;
  width: 30px;
  background-color: white;
  &::before, &::after {
    background-color: white;
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
  }
  &::before {
    top: 18px;
  }
  &::after {
    top: 9px;

  }
}
`;

const MobileMenuContainer = styled.div`
  background-color: ${props => props.theme.darkerBlue};
  height: 100vh;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  width: clamp(20em, 35vw, 50em);
  z-index: 4;
  + section {
    height: 100%;
    width: 100%;
    position: fixed;
    lefT: 0;
    top: 0;
    z-index: 3;
  }
  ul {
    list-style-type: none;
    li {
      font-weight: 700;
      line-height: 3.5em;
      &:last-of-type {
        a {
          background-color: white;
          border-radius: 0.5em;
          color: ${props => props.theme.darkerBlue};
          padding: 0.5em;
          &:hover {
            background-color: ${props => props.theme.lightBlue};
            color: white;
          }
        }
      }
      a {
        color: white;
        font-size: 1.5em;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.1s ease-in, background-color 0.1s ease-in;
        &:hover {
          color: ${props => props.theme.lightBlue};
        }
      }
    }
  }
  > div { // close button
    color: white;
    cursor: pointer;
    position: absolute; 
    right: clamp(0.25em, 3vw, 1em);
    top: 0.5em;
    font-weight: 700;
    font-size: 2em;
    transition: color 0.10s ease-in;
    &:hover {
      color: ${props => props.theme.lightBlue};
    }
  }
`


export {NavList, HamburgerMenu, MobileMenuContainer}