import React, { useContext } from 'react';
import { ProductsContainer } from './Products.styles.tsx';
import ProductItem from './ProductItem/ProductItem.tsx';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { ModalContext, ProductContext } from '../../App.js';
import useWindowDimensions from '../../hooks/Hooks.tsx';

// type Props = {}

// console.log(ProductContext);
// const {productsObj} = ProductContext;

// console.log(ModalContext);

const productCodes = ['MK', 'BCK', 'HBCK', 'TKR', 'MG', 'GH', 'CM', 'LS', 'SM', 'FS'];

// const {productCodes} = ModalContext;

const productName = (productCode) => {
  switch (productCode) {
    case 'MK':
      return 'Manchester Kimchi';
    case 'BCK':
      return 'Baltimore Curry Kimchi';
    case 'HBCK':
      return 'HABANERO Baltimore Curry Kimchi';
    case 'TKR':
      return 'Tasty Kraut';
    case 'MG':
      return 'Malignant Gestation Chile Sauce';
    case 'GH':
      return 'Guajillo Habanero Chile Sauce';
    case 'CM':
      return 'Chipotle Miso Chile Sauce';
    case 'LS':
      return 'Lemon Serrano Chile Sauce';
    case 'SM':
      return 'Serrano Mustard Chile Sauce';
    case 'FS':
      return 'Fruity Serrano Chile Sauce';
  }
}

const handleClick = (productCode, modalContext, productContext) => {
    const {setActiveModal} = modalContext;

    // console.log(setActiveModal);

    switch (productCode) {
    case 'MK':
      setActiveModal({
        MK: true, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'BCK':
      setActiveModal({
        MK: false, 
        BCK: true,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'HBCK':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: true, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'TKR':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: true,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'MG':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: true, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'GH':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: true,
        CM: false,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'CM':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: true,
        LS: false,
        SM: false,
        FS: false,
      });
      return;
      case 'LS':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: true,
        SM: false,
        FS: false,
      });
      return;
      case 'SM':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: true,
        FS: false,
      });
      return;
      case 'FS':
      setActiveModal({
        MK: false, 
        BCK: false,
        HBCK: false, 
        TKR: false,
        MG: false, 
        GH: false,
        CM: false,
        LS: false,
        SM: false,
        FS: true,
      });
      return;
  }
}

const ProductSlider = (modalContext, productContext) => {
  // do something here with product context to get product names
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      {productCodes.map((p, i) => (
        <>
        <header>
          <h4>
            {productName(p)}
          </h4>
        </header>
        <div onClick={() => handleClick(p, modalContext, productContext)}>
          <img alt='product' src={require('../../assets/products/' + p + '2024.jpg')} />
        </div>
        </>
      ))}
    </Slider>
  );
}

const getProducts = (deviceWidth, modalContext, productContext) => {
  if (deviceWidth > 541) {
    return (
      <ul>
        {productCodes.map((p, i) => (
          <ProductItem key={i} productCode={p} productName={productName} handleClick={handleClick} />
        ))}
      </ul>
    )
  } else {
    return (
      ProductSlider(modalContext, productContext)
    )
  }
}

function Products() {
  const modalContext = useContext(ModalContext);
  const productContext = useContext(ProductContext);

  const { width } = useWindowDimensions();
  return (
    <ProductsContainer>
      <div id='products'>
        <h3>Products</h3>
        {getProducts(width, modalContext, productContext)}
      </div>
    </ProductsContainer>
  );
}

export default Products;
