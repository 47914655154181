import React from 'react';
import { ContactContainer } from './Contact.styles.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

// type Props = {}

function Contact() {
  return (
    <ContactContainer>
      <h5>Follow us on the socials</h5>
      <a target='_blank' href='https://www.facebook.com/fermfoundations' rel="noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a target='_blank' href='https://www.instagram.com/fermfoundations' rel="noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <div>
        <a className='button' href='mailto:manchesterkimchi@gmail.com' target='_blank'>
          Send us an Email
        </a>
      </div>
    </ContactContainer>
  );
}

export default Contact;
