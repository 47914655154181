import styled from 'styled-components';

const HeroContainer = styled.div`
background-color: ${(props) => props.theme.lightBlue};
background-image: url(${require('../../assets/hero/test-bg4.jpg')});
background-size: cover;
background-position: center;
color: white;
padding: ${(props) => props.theme.padding};
position: relative;
text-align: center;
&::after {
    content: '';
    background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0) 100%
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
  > * {
    position: relative;
    text-shadow: 0.06em 0.06em 0 rgba(0, 0, 0, 0.5);
    z-index: 1;
}
`;

export { HeroContainer };
