import React from 'react';
import { AboutContainer, AboutGrid } from './About.styles.tsx';

// type Props = {}

function About() {
  return (
    <AboutContainer>
      <div id='about'>
        <h3>About Us</h3>
        <AboutGrid>
          <div>
            <img alt='Jar of Experiments' src={require('../../assets/about/about1.jpg')} />
          </div>
          <div>
            <h5>Harnessing the Power of Fermentation</h5>
            <p>
              Ferm Foundations has a core passion for fermentation and all of the
              flavors that it can facilitate. Our primary goal is to create culinary marvels
              from high quality, fresh ingredients while working hand-in-hand with
              nearly-magic microbial chefs.
            </p>
          </div>
          <div>
            <h5>A Fermented Family Legacy</h5>
            <p>
              Our president and co-founder spent childhood Thanksgivings creating
              sauerkraut with family, a ritual that laid the groundwork for his
              lifelong fascination with fermentation. This passion extended beyond
              sauerkraut, leading to an exploration of kimchi's vibrant flavors.
            </p>
          </div>
          <div>
            <h5>Beyond Tradition, Embracing Innovation</h5>
            <p>
              While honoring traditional techniques, we also wholeheartedly embrace
              experimentation. While enjoying kimchi with eggs, we recognized that
              its classic ocean-forward notes might not always be the perfect
              pairing. This insight led to countless iterations, ultimately
              resulting in our flagship product: Manchester Kimchi, named after its
              birthplace in Manchester, Pennsylvania.
            </p>
          </div>
          <div>
            <h5>A Fermentation Revolution</h5>
            <p>
              Manchester Kimchi's success fueled our drive to push boundaries.
              Utilizing its vegan base, we crafted Baltimore Curry Kimchi, a
              testament to our commitment to expanding the world of fermented
              flavors. Today, Ferm Foundations offers a constantly evolving
              selection of cutting-edge fermented products, including kimchi, hot
              sauces, and more.
            </p>
          </div>
                    <div>
            <img alt='Jar of Experiments' src={require('../../assets/about/about2.jpg')} />
          </div>
        </AboutGrid>
        <p>
          Be sure to check out our
          <a href='https://www.facebook.com/fermfoundations'>Facebook</a>
          and
          <a href='https://www.instagram.com/fermfoundations'>Instagram</a>
          pages for the most up to date information on our events!
        </p>
      </div>
    </AboutContainer>
  );
}

export default About;
