import React from 'react';
import { FaqsContainer } from './Faqs.styles.tsx';
import FaqsItem from './FaqsItem.tsx';

const faqList = [
  {
    question: 'What is kimchi?',
    answer:
      'Kimchi is the Korean word for "pickle". While there are many traditional varieties, the most common type is primarily made with napa cabbage. Similar to sauerkraut, kimchi is pickled through the probiotic power of fermentation. The kimchi that we make is made using Napa cabbage, Daikon radish, and carrots.',
  },
  {
    question:
      'What makes Manchester Kimchi and Baltimore Curry Kimchi different from traditional kimchi?',
    answer:
      'At Ferm Foundations, we do not feel restricted by tradition, and that allows us to push the limits of what kimchi can be! All of our kimchi is 100% vegan, containing none of the traditional seafood ingredients. The flavor is much more substantial and the consistency is more concentrated. This allows our kimchi to be used more as a condiment rather than only a side-dish. Our kimchi is also fully fermented in a controlled enviroment that allows more garlic and ginger to be added without overpowering. For this reason, all of our kimchi is less likely to change in flavor over time in a refrigerator.',
  },
  {
    question: 'How do I use kimchi?',
    answer:
      'Traditionally kimchi is eaten as a side dish and palate cleanser. Our kimchi is more concentrated in flavor and that makes using it as a condiment great! Perfect on burgers, hot dogs, grilled cheese, or cooked veggies, our kimchi can completely transform meals into interesting and novel experiences. It is also perfectly fine to enjoy some straight from the jar.',
  }
];

// type Props = {}

function Faqs() {
  return (
    <FaqsContainer>
      <div id='faqs'>
        <h3>Frequently Asked Questions</h3>
        {
          faqList.map((f) => <FaqsItem faq={f} />)
        }
      </div>
    </FaqsContainer>
  );
}

export default Faqs;
